const Typesense = require('typesense');

export default new Typesense.Client({
  'nodes': [{
    'host': '45wjfnkqm0hi1bzop-1.a1.typesense.net', // For Typesense Cloud use xxx.a1.typesense.net
    'port': 443,                                    // For Typesense Cloud use 443
    'protocol': 'https'                             // For Typesense Cloud use https
  }],
  'apiKey': 'NpocJ175pwynh55EdjTXCWIV6LhqcaKD',
  'connectionTimeoutSeconds': 2
})